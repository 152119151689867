export const environment = {
  firebase: {
    apiKey: "AIzaSyC-e98LbRSKBXlhjId0osL_RaQeIooAgl4",
    authDomain: "aaya-admin.firebaseapp.com",
    databaseURL: "https://aaya-admin.firebaseio.com",
    projectId: "aaya-admin",
    storageBucket: "aaya-admin.appspot.com",
    messagingSenderId: "377769970024",
    appId: "1:377769970024:web:b84de5a21b738427"
  },
  production: true
};
