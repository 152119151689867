import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/@core/auth.service';

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styles: []
})
export class LoginBoxedComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ngOnInit() {
  }

  signIn(email: string,password: string){
    console.log(email, password);
    this.auth.emailLogin(email, password);
  }

}
