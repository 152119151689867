import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from 'src/app/@core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kb-list',
  templateUrl: './kb-list.component.html',
  styleUrls: ['./kb-list.component.sass']
})
export class KbListComponent implements OnInit {
  projectID: string;
  KB: string;
  createDocButtonStatus: boolean = false;
  docLink: string;
  docName: string;
  docList: any;
  

  constructor(private fns: AngularFireFunctions, private auth: AuthService, private afs: AngularFirestore, private router: Router) { 
    
    this.auth.user.subscribe(user=>{
      this.projectID = user.projectID;

      if(user.KB){
        this.KB = user.KB;
        this.getDocs();
      }else{
        const callable = fns.httpsCallable('getKBList');
        let kbList = callable({ projectID:user.projectID});
        kbList.subscribe(list=>{
          list[0].forEach((element: any) => {
            
            if(element.displayName == "KB1"){
              console.log(element);
              this.afs.doc(`users/${user.uid}`).update({
                KB: element.name
              });
            }
            
          });
        });
      }
    });

    
  }

  ngOnInit() {

  }

  getDocs(){
    console.log("rin get docs");
    const callable = this.fns.httpsCallable('getDocs');
    let getDocs = callable({ 
      projectID: this.projectID,
      knowledgeBaseFullName: this.KB
    });
    getDocs.subscribe(list=>{
      console.log(list);
      this.docList = list;
    });
      
  }

  addDoc(link, name){
    console.log(link);
    console.log(name);
    this.createDocButtonStatus = true;
    const callable = this.fns.httpsCallable('addDoc');
    let getDocs = callable({ 
      projectID: this.projectID,
      knowledgeBaseFullName: this.KB,
      path: link,
      name: name
    });
    getDocs.subscribe(list=>{
      this.createDocButtonStatus = false;
      
      console.log(list);
      
      this.router.navigateByUrl('/RefrshComponent', {skipLocationChange: true}).then(()=>
      this.router.navigate(["/knowledgebase"])); 
    });
      
  }

}
