import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/@core/auth.service';

@Component({
  selector: 'app-register-boxed',
  templateUrl: './register-boxed.component.html',
  styles: []
})
export class RegisterBoxedComponent implements OnInit {

  constructor(private auth: AuthService) {}

  ngOnInit() {
  }

  signUp(email: string,password: string){
    console.log(email, password);
    this.auth.emailSignUp(email, password);
  }


}
