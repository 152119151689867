import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/@core/auth.service';

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.sass']
})
export class PersonaComponent implements OnInit {
  user: any;
  name: any;
  nameShort: any;


  

  constructor(private afs: AngularFirestore, private auth: AuthService) { 
    var botRef = afs.collection("bot");

    auth.user.subscribe(user=>{

      this.user = user;
      if(user.botID){
         this.afs.doc('bot/'+user.botID).valueChanges().subscribe(doc=>{
           console.log(doc);
           this.name = doc['name'];
           this.nameShort = doc['nameShort'];
         });
      }


      // this.afs.collection('bot', ref => ref.where('projectID', '==', user.projectID));
      // let botRef = this.afs.collection('bot', ref => ref.where('projectID', '==', user.projectID).limit(1)).valueChanges();
      // botRef.subscribe(bot=>{
      //   console.log(bot);
      // })
    })

    
  }

  ngOnInit() {
  }

  saveInfo(name,nameShort){
    if(this.user.botID){
      this.afs.doc('bot/'+this.user.botID).update({
        name: name,
        nameShort: nameShort
      });
    }else{
      this.afs.collection('bot').add({
        users: [this.user.uid],
        name: name,
        nameShort: nameShort,
        projectID: this.user.projectID
      }).then(doc=>{
        console.log(doc);
        this.afs.doc('users/'+this.user.uid).update({
          botID: doc['id']+''
        });
      });
    }
  }

}
