import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {

  constructor(private fns: AngularFireFunctions) { 

    console.log('jhgbjhbg')
    //const callable = fns.httpsCallable('addKB');
    //callable({ projectID:'aaya-bot4-yeshpb',KBName:  'some-data' });
  }

  ngOnInit() {
  }

}
